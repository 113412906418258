import React from 'react'
import Feature from '../Feature'
import data from '../../../data/home.json'

const Features = () => (
  <div className="row-spaced">
    <div className="container">
      <div className="row">
        <div className="col-md-12 ml-auto mr-auto text-center align-self-center">
          <div>
            <h2 className="title">Everything you need to start renting</h2>
            <p className="subtitle">
              Our rental software gives you everything you need to run your
              rental business. Manage all your customers and products in one
              place. Make orders of available items only and stop worrying of
              renting the same product for different customers at the same time.
            </p>
          </div>
        </div>
      </div>
      <div className="row features carded">
        {data.features.map((feature, i) => (
          <Feature
            key={i}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  </div>
)

export default Features
