import React from 'react'
import Benefit from '../Benefit'
import data from '../../../data/home.json'

const Benefits = () => (
  <div className="features row-spaced">
    <div className="container">
      <div className="row">
        {data.benefits.map((benefit, i) => (
          <Benefit
            key={i}
            title={benefit.title}
            description={benefit.description}
            icon={benefit.icon}
          />
        ))}
      </div>
    </div>
  </div>
)

export default Benefits
