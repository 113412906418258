import React from 'react'
import Icon from '../../Icon'

const Feature = ({ title, description, icon }) => (
  <div className="feature col-md-4">
    <div className="feature-inner">
      <Icon className="feature-icon" name={icon} size="50" />
      <h3 className="feature-title">{title}</h3>
      <p className="feature-description">{description}</p>
    </div>
  </div>
)

export default Feature
