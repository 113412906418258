import React from 'react'
import Img from 'gatsby-image'

const LaptopSection = ({ image }) => (
  <div className="text-center row-spaced container-padded">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <Img
            className="img-fluid"
            alt="Rental Software"
            sizes={image.childImageSharp.sizes}
          />
        </div>
        <div className="col-lg-4 align-self-center">
          <div>
            <h2 className="title title-30">
              The complete software for any kind of rental business
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LaptopSection
