import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/Layout'
import Header from '../components/Header'
import Benefits from '../components/home/Benefits'
import LaptopSection from '../components/home/LaptopSection'
import Features from '../components/home/Features'
import GetStarted from '../components/GetStarted'
import Footer from '../components/Footer'

const IndexPage = ({ data }) => (
  <Layout path="/">
    <div>
      <Header />
      <Benefits />
      <LaptopSection image={data.laptopImage} />
      <Features />
      <GetStarted />
      <Footer withContact />
    </div>
  </Layout>
)

export const query = graphql`
  query IndexQuery {
    laptopImage: file(
      relativePath: { eq: "laptop.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        sizes(maxWidth: 900) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
  }
`

export default IndexPage
