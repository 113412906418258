import React, { PureComponent } from 'react'
import Navbar from '../Navbar'
import './Header.scss'

import headerImg from '../../images/header.jpg'

const loadImage = src => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(src)
    image.onerror = err => reject(err)
    image.src = src
  })
}

class Header extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      headerImage: null,
    }
  }

  componentDidMount() {
    this.loadHeaderImage()
  }

  loadHeaderImage() {
    loadImage(headerImg).then(url => this.setState({ headerImage: url }))
  }

  render() {
    const { headerImage } = this.state
    const style = {}
    if (headerImage) {
      style.backgroundImage = `url(${headerImage})`
    }

    return (
      <div id="home" className="header" style={style}>
        <div className="header-overlay">
          <Navbar />

          <div className="container">
            <div className="row">
              <div className="col-sm-8 ml-auto mr-auto text-center">
                <div className="header-content">
                  <h1 className="header-title">
                    A rental software made for you
                  </h1>
                  <h2 className="header-description">
                    A rental software for managing, tracking and planning your
                    inventory. Stop feeling lost in your data.
                  </h2>
                  <a
                    className="btn btn-action btn-lg mt-4"
                    href="https://signup.brentiv.com"
                  >
                    Get started for free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
