import React from 'react'
import Icon from '../../Icon'

const Benefit = ({ icon, title, description }) => (
  <div className="feature col-sm-6 col-md-3">
    <Icon className="feature-icon" name={icon} size="60" />
    <h3 className="feature-title">{title}</h3>
    <p className="feature-description">{description}</p>
  </div>
)

export default Benefit
