import React from 'react'

const GetStarted = () => (
  <div className="light-background text-center row-spaced-top container-padded">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8 ml-auto mr-auto">
          <h2 className="title">Ready to get started?</h2>
          <p className="subtitle">
            Get in touch, or create your company account. This will takes only
            few minutes to start making orders for your products.
          </p>
          <div>
            <a
              className="btn btn-primary btn-lg mb-4"
              href="https://signup.brentiv.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Create an account
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default GetStarted
